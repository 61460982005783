@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @import url('https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap');
    h1, h2, h3, h4, h5, header p, .boxhead {
        font-family: 'Racing Sans One', cursive;
        @apply filter drop-shadow;
    }
    h2, h3, h4, h5 {
        @apply text-secondarytwo-light dark:text-secondarytwo pt-6 pb-2;
    }
    h2 {
        @apply text-4xl lg:text-5xl;
    }
    @media (min-width: 1024px) {
        h2 {
            font-size: 2.5rem;
        }
    }

    h3 {
        @apply text-3xl lg:text-4xl;
    }
    h4, .boxhead {
        @apply text-2xl lg:text-3xl;
    }
    h5 {
        @apply text-2xl;
    }
    /* Technically these could be left in the html since there is only one main, but I put them here for convenience */
    main {
        @apply md:w-11/12 lg:w-5/6 max-w-screen-xl mx-auto bg-primary-pale dark:bg-gray-900 text-gray-900 dark:text-primary-darkmodetext pl-6 pr-5 pb-4 sm:pb-12 sm:px-14 mb-4;
    }
    .footertext {
        @apply md:w-11/12 lg:w-5/6 max-w-screen-xl mx-auto py-4 text-primary-pale text-sm px-6;
    }
    p {
        @apply py-1;
    }
    ul {
        @apply list-disc;
    }
    li {
        @apply pb-1;
    }
    a {
        @apply underline text-primary dark:text-secondaryone-pale focus:text-complementary dark:focus:text-complementary-light;
    }
    nav a {
        @apply inline-block text-center p-2 no-underline text-secondaryone-verydark dark:text-secondaryone-pale  hover:bg-complementary-verylight rounded-md hover:text-gray-900 dark:hover:text-secondaryone-verydark;
    }
    nav li {
        @apply md:m-3;
    }
    figure {
        @apply bg-white dark:bg-primary-darkmodetext p-5 max-w-lg;
    }
    figure img {
        @apply w-full h-full object-cover dark:filter brightness-75 contrast-125;
    }
    figcaption {
        @apply pt-2 italic dark:text-primary-verydark;
    }
    /* .box figcaption {
        @apply text-gray-900;
    } */
}

@layer components {

    /* I tried making the .boxes full-width on mobile, but didn't like it */

    .column-nowrap {
        @apply lg:grid lg:grid-flow-col lg:gap-10;
        grid-template-columns: 2fr 1fr;
    }
    .column-nowrap-reverse {
        @apply lg:grid lg:grid-flow-col lg:gap-10;
        grid-template-columns: 1fr 2fr;
    }
    .halfsies {
        @apply lg:grid lg:gap-10 lg:grid-cols-2;
    }
    .box {
        @apply place-self-start bg-gradient-to-br from-secondarytwo-light dark:from-secondarytwo-dark to-green-600 dark:to-green-800 text-white dark:text-primary-darkmodetext rounded p-6 shadow;
    }
    .box, figure {
        @apply my-6 lg:mt-1;
    }
    /* changes display of the aside box in the place it needs to float right */
    @media (max-width: 1023px) {
        .box-float {
            display: none;
        }
    }
    @media (min-width: 1024px) {
        .box-float {
            @apply float-right ml-8 mb-4 w-1/3;
        }
        .box-last {
            display: none;
        }
    }
   
    /* styling for arrow and yoyo emoji paras */
    
    .question {
        font-style: italic;
    }
    .question::before {
        content: "\01FA80";
        font-style: normal;
        @apply pr-2;
    }
    @media (min-width: 768px) {
        .point::before {
            content: "\2192";
            @apply text-secondaryone-dark dark:text-secondaryone-pale font-bold pr-2 text-2xl -ml-8 relative top-px;
        }
        .question::before {
            @apply text-2xl -ml-8 relative top-0.5;
        }
        .point, .question {
            @apply pl-8;
        }
     }
}


